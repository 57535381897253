import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import isMobile from 'is-mobile';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Collapse } from '@blueprintjs/core';

import Login from './dialog/Login/Login';
import Signup from './dialog/Signup/Signup';
import ResetPassword from './dialog/ResetPassword/ResetPassword';
import NewPassword from './dialog/NewPassword/NewPassword';
import Logo from '../imgs/logo.svg';
import MenuIcon from '../imgs/menuIcon.svg';
import Cross from '../imgs/cross.svg';

import { useAuth, logout } from './hook/useAuth';
import { redirectUrl } from './config/endPoints';
import { redirectDashUrl  } from './config/endPoints';

import { useRouter } from 'next/router';

import Cookies from 'js-cookie';

const Header = () => {
  const [mbNavOpen, setMbNavOpen] = useState(false);
  const [haveToken, setHaveToken] = useState(false);
  /*
  useEffect(() => {
    getCookiesData();
  }, []);
  */
  const router = useRouter();

  const getCookiesData = () => {
    if (typeof window !== 'undefined') {
      if (Cookies.get('token')) {
        setHaveToken(true);
      }
    }
  };

  

  const {
    token,
    user,
    isShowLogin,
    isShowSignup,
    isShowNewPassword,
    isShowResetPassword,
    resetPasswordCode,
    setShowLogin,
    setShowSignup,
    setShowNewPassword,
    setShowResetPassword,
    setResetPasswordCode,
  } = useAuth();

  useEffect(() => {
    getCookiesData();
    // check if the page is loaded with a reset password type and code
    if (router.query.type === "reset" && router.query.code) {

      setShowNewPassword(true); // show the new password form
    }
  }, [router.query]);


  const resetPasswordProc = () => {
    setShowLogin(false);
    setShowResetPassword(true);
  };
  const registerSuccessProc = () => {
    setShowSignup(false);
  };

  return (
    <div className="newHeader">
      <nav className="navbar">
        <div className="navbar__container eightyPercentContainer">
          <div className="navbar__logo">
            <Link href="/" title="Home Page" passHref>
              <div className="logoBox">
                <Image src={Logo} alt="Mendelgen Logo" />
              </div>
            </Link>
          </div>
          <div className="navbar__actions navbar__actions--bigScreen">
            <Link href="/docs" title="Open Documentation" passHref>
              <a>
                <span className="navLink">User Guide</span>
              </a>
            </Link>

            {!haveToken && (
              <button
                id="signin"
                className="sign"
                onClick={() => {
                  setShowLogin(true);
                }}
              >
                <span className="navLink">Sign In</span>
              </button>
            )}

            <Button
              id="signup"
              className="sign"
              onClick={() => {
                if (haveToken) {
                  document.location.href = redirectDashUrl;
                } else {
                  setShowSignup(true);
                }
              }}
              style={{
                marginLeft: haveToken ? 25 : 0,
              }}
            >
              <span className="navLink">{haveToken ? 'Go To App' : 'Sign Up'}</span>
            </Button>
          </div>
          {!mbNavOpen && (
            <div className="navbar__actions--menuIcon" onClick={(e) => setMbNavOpen(true)}>
              <Image src={MenuIcon} alt="Open Menu" />
            </div>
          )}

          {mbNavOpen && (
            <div
              className="navbar__actions--menuIcon navbar__actions--menuIcon--cross"
              onClick={(e) => setMbNavOpen(false)}
            >
              <Image src={Cross} alt="Close Menu" className="cross" />
            </div>
          )}
        </div>
      </nav>
      <Collapse isOpen={mbNavOpen}>
        <div className="navbar__actions navbar__actions--sm">
          <Link href="/docs" title="Open Documentation" passHref>
            <a>
              <div className="mbNavLink">
                <span className="navLink">User Guide</span>
              </div>
            </a>
          </Link>
          <button
            id="signin"
            className="sign"
            style={{ display: 'block' }}
            onClick={() => {
              if (haveToken) {
                document.location.href = redirectDashUrl;
              } else {
                setShowLogin(true);
              }
            }}
          >
            <div className="mbNavLink">
              <span className="navLink">{haveToken ? 'Go To App' : 'Sign In'}</span>
            </div>
          </button>
          {!haveToken ? (
            <Button
              id="signup"
              className="sign"
              onClick={() => {
                setShowSignup(true);
              }}
              style={{
                display: 'unset',
              }}
            >
              <span className="navLink">Sign Up</span>
            </Button>
          ) : null}
        </div>
      </Collapse>

      {/* <nav className="navbar sticky-top">
      <div className="navbar__container">
        <div className="col-7 col-sm-4 col-md-4 col-lg-4 nav_cell navbar__logo">
          <div>
            <a href="/">
              <img
                alt="logo"
                src={"/img/logo-placeholder.svg"}
                width=""
                className="logo"
              />
            </a>

            <a className="navbar-brand" href="/">
              mendelgen
            </a>
            <div
              className="beta"
              style={{
                textAlign: "right",
                marginTop: "-5px",
                paddingRight: "18px",
                fontSize: "10px",
                color: "#444444"
              }}
            >
              beta
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-4 nav_cell login_part navbar_button">
          {!isUserLoggedIn && (
            <div style={{ display: "block" }}>
              <Link to="/docs" title="Open Documentation">
                <button id="signin">User Guide</button>
              </Link>
              <button
                id="signin"
                className="sign"
                onClick={() => {
                  setShowLogin(true);
                }}
              >
                Sign In
              </button>
              <button
                id="signup"
                className="sign"
                onClick={() => {
                  setShowSignup(true);
                }}
              >
                
              </button>
            </div>
          )}  
          {isUserLoggedIn && !isMobile() && (
            <div
              className="col-6 col-sm-6 login_part right"
              style={{
                display: "flex",
                alignItems: "center",
                padding: isMobile() ? "0" : "unset",
                flexDirection: isMobile() ? "column" : "row"
              }}
            >
              <div style={{ display: "block" }}>
                <div
                  onClick={(e) => {
                    setShowMenuBar(!showMenuBar);
                  }}
                  className="toolbarBtn"
                  style={{
                    width: "35px",
                    height: "35px",
                    marginLeft: "10px",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer"
                  }}
                >
                  <img
                    style={{ margin: "auto" }}
                    src={"/img/personLight.svg"}
                    alt="profile"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav> */}
      {/* {showMenuBar && isUserLoggedIn && (
        <div id="menuBarWrapper" style={{ width: '100%', marginTop: '4.6rem' }}>
          <div
            className="veMenuBarContainer userMenuBar"
            style={{ display: 'flex' }}
          >
            <div className="tg-menu-bar ">
              <button
                type="button"
                className="bp3-button bp3-minimal tg-menu-bar-item"
                onClick={() => {
                  logout();
                  document.location.href = '/';
                }}
              >
                <span className="bp3-button-text">Log out</span>
              </button>
            </div>
            <div
              className="menuBarDivider"
              style={{
                height: '87%',
                width: 0,
                margin: '2px',
                background: 'lightgrey',
              }}
            ></div>
          </div>
        </div>
      )} */}
      <Login
        showModal={isShowLogin}
        closeProc={() => {
          setShowLogin(false);
        }}
        resetPassword={resetPasswordProc}
      />
      <Signup
        showModal={isShowSignup}
        closeProc={() => {
          setShowSignup(false);
        }}
        successProc={registerSuccessProc}
      />
      <ResetPassword
        showModal={isShowResetPassword}
        closeProc={() => {
          setShowResetPassword(false);
        }}
      />
      <NewPassword
        showModal={isShowNewPassword}
        closeProc={() => {
          //props.history.push('/');
          router.push("/");
          setShowNewPassword(false, '');
        }}
        resetCode={router.query.code}
        successProc={() => {
          setShowNewPassword(false, '');
          //props.history.push('/');
          router.push("/");
        }}
      />
    </div>
  );
};

export default Header;
