import { useState, useEffect } from 'react';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
// import styles from '../styles/Home.module.css';
import { Formik } from 'formik';
import { Button } from '@blueprintjs/core';
import axios from 'axios';

import { useAuth } from "../components/hook/useAuth";
import { generateFormData } from "../components/utils/keHelper";
import HeroImage from '/imgs/hero.png';
//import { validation } from "../../dialog/Signup/validate";
import DesignIcon from '../imgs/design.svg';
import DesignImg from '../imgs/design.png';
import AlignImg from '../imgs/align2.svg';
import rect1 from '../imgs/rect.png';
import rect2 from '../imgs/rect2.png';
import rect3 from '../imgs/rect3.png';
import rect4 from '../imgs/rect4.png';
import PreviewImg from '../imgs/preview.svg';
import Quick from '../imgs/quick.svg';
import Codon from '../imgs/codon.svg';
import CodonImg from '../imgs/codon.png';
import DarkMode from '../imgs/darkMode.svg';
import Access from '../imgs/accessIcon.svg';
import VisualizeIcon from '../imgs/visualizeIcon.svg';
import cloneTool from '../imgs/cloneTool.png';
import Tick from '../imgs/tick.svg';
import Beta from '../imgs/beta.svg';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { cookieOptions, originDomain, redirectUrl, registerUrl } from '../components/config/endPoints'
import Cookies from 'js-cookie';

import Vimeo from '@u-wave/react-vimeo';

import { Nav, Row, Tab, Tabs } from "react-bootstrap";
import WhiteTick from '../imgs/whiteTick.svg';
import BlueTick from '../imgs/blueTick.svg';

import SSRProvider from 'react-bootstrap/SSRProvider';

import va from '@vercel/analytics';
import { useToasts } from 'react-toast-notifications'

export default function Home() {
  const { login, setShowSignup, setShowNewPassword, setShowLogin, registration } = useAuth();
  const [haveToken, setHaveToken] = useState(false)


  useEffect(() => {
    getCookiesData();
  }, []);

  const getCookiesData = () => {
    if (typeof window !== "undefined") {
      if (Cookies.get("token")) {
        setHaveToken(true);
      }
    }
  };

  const [player, setPlayer] = useState()

  const { addToast } = useToasts();

  const signUpFromNext = (values) => {
    const formData = generateFormData(values);

    axios.post(registerUrl, formData)
      .then(({data}) => {
        Cookies.set("token", data.jwt, cookieOptions);
        Cookies.set("userId", data.user.id, cookieOptions);
        Cookies.set("userEmail", data.user.email, cookieOptions);
        Cookies.set("userName", data.user.username, cookieOptions);
        Cookies.set('isNewUser', true, cookieOptions);
        document.location.href = redirectUrl;
      })
      .catch(error => {
        console.log(error);
        //resultProc("failed")
        addToast('Registration failed', { appearance: 'error' });
      });


      
}


const handleButtonClick = () => {
  va.track('Signup');
};

  return (
    <SSRProvider>
    <div>
      <Head>
        <title>Mendelgen - Plasmid Design Simplified</title>
        <meta
          name="description"
          content="Streamline your research with an intuitive, powerful tool for plasmid and vector design, codon optimization, and more. Start designing plasmids more efficiently today!"
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Header />
      <div className="home_page newHomePage">
        <section className="hero eightyPercentContainer">
          <div className="content">
            <div className="content__left">
              <div className="content__info">
                <h1>Plasmid Design Simplified</h1>
                <span>
                Catalyze your research with simple-to-use yet powerful tools for plasmid design.
                </span>
              </div>
              <div className="imgBox">
                <Image src={HeroImage} alt="Hero Area" placeholder='blur'/>
              </div>
              <div className="content__form">
                <Formik
                  initialValues={{
                    username: '',
                    email: '',
                    password: '',
                  }}
                  validateOnBlur={false}
                  validateOnChange={false}
                  validateOnMount={false}
                  validate={(values) => console.log(values)}
                  onSubmit={(values, { setSubmitting }) => {
                    //console.log("sign up step1")
                    signUpFromNext(values)
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit} style={{ opacity: haveToken ? 0.3 : 1}}>
                      <div className="formGroup">
                        <div className="formGroup__group">
                          <svg
                            width="21"
                            height="16"
                            viewBox="0 0 21 16"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M18 0C19.6569 0 21 1.34315 21 3V13C21 14.6569 19.6569 16 18 16H3C1.34315 16 0 14.6569 0 13V3C0 1.34315 1.34315 0 3 0H18ZM18 1.5H3C2.92441 1.5 2.85014 1.50559 2.77755 1.51638L9.69483 7.34146C10.1243 7.70313 10.7366 7.73095 11.1942 7.42492L11.3052 7.34146L18.2224 1.51638C18.1967 1.51255 18.1707 1.50937 18.1445 1.50687L18 1.5ZM19.4073 2.47964L12.2714 8.48882C11.2965 9.30977 9.8936 9.34886 8.87797 8.6061L8.72863 8.48882L1.59272 2.47964C1.54873 2.59857 1.51938 2.7246 1.50687 2.85554L1.5 3V13C1.5 13.7797 2.09489 14.4204 2.85554 14.4931L3 14.5H18C18.7797 14.5 19.4204 13.9051 19.4931 13.1445L19.5 13V3C19.5 2.81705 19.4672 2.64175 19.4073 2.47964Z"
                              fill={
                                errors.email && touched.email
                                  ? 'red'
                                  : '#9B9CAA'
                              }
                            />
                          </svg>

                          <input
                            placeholder="Email"
                            type="email"
                            id="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={haveToken}
                            value={values.email}
                            className={
                              errors.email && touched.email ? 'inputError' : ''
                            }
                          />
                        </div>
                        <div className="formGroup__errorText">
                          {errors.email}
                        </div>
                      </div>

                      <div className="formGroup">
                        <div className="formGroup__group">
                          <svg
                            width="17"
                            height="19"
                            viewBox="0 0 17 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M8.49973 0C11.2296 0 13.4427 2.23858 13.4427 5C13.4427 6.669 12.6342 8.14701 11.3919 9.05521C13.416 9.86242 15.0668 11.5002 15.8726 13.6397L16.8699 16.288C17.2587 17.3202 16.7466 18.4758 15.7262 18.869C15.5014 18.9556 15.2628 19 15.0223 19H1.97717C0.885208 19 0 18.1046 0 17C0 16.7567 0.0438952 16.5154 0.129529 16.288L1.1269 13.6397C1.93262 11.5002 3.58347 9.86242 5.60756 9.05521C4.36522 8.14701 3.55681 6.669 3.55681 5C3.55681 2.23858 5.76983 0 8.49973 0ZM8.49973 8.5C10.4107 8.5 11.9598 6.933 11.9598 5C11.9598 3.067 10.4107 1.5 8.49973 1.5C6.5888 1.5 5.03969 3.067 5.03969 5C5.03969 6.933 6.5888 8.5 8.49973 8.5ZM8.49973 10C5.92704 10 3.613 11.5553 2.60866 13.9332L2.51263 14.1737L1.51526 16.822C1.49385 16.8788 1.48288 16.9392 1.48288 17C1.48288 17.2455 1.65773 17.4496 1.88832 17.4919L1.97717 17.5H15.0223C15.0824 17.5 15.1421 17.4889 15.1983 17.4672C15.425 17.3799 15.5514 17.1419 15.5084 16.9089L15.4842 16.822L14.4868 14.1737C13.5404 11.6607 11.1582 10 8.49973 10Z"
                              fill={
                                errors.username && touched.username
                                  ? 'red'
                                  : '#9B9CAA'
                              }
                            />
                          </svg>

                          <input
                            placeholder="Full Name"
                            type="text"
                            name="username"
                            disabled={haveToken}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.username}
                            className={
                              errors.username && touched.username
                                ? 'inputError'
                                : ''
                            }
                          />
                        </div>
                        <div className="formGroup__errorText">
                          {errors.username}
                        </div>
                      </div>

                      <div className="formGroup">
                        <div className="formGroup__group">
                          <svg
                            width="17"
                            height="18"
                            viewBox="0 0 17 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M14.7368 5.92343C14.5263 2.61629 11.8144 0 8.5 0C5.18558 0 2.47374 2.61628 2.26316 5.92343C0.962567 6.25649 0 7.45087 0 8.87324V14.9577C0 16.6379 1.34315 18 3 18H14C15.6569 18 17 16.6379 17 14.9577V8.87324C17 7.45087 16.0374 6.25649 14.7368 5.92343ZM13.224 5.83099C12.9741 3.40901 10.9545 1.52113 8.5 1.52113C6.04552 1.52113 4.02585 3.40902 3.776 5.83099H13.224ZM14.1445 7.35908L14 7.35211H3C2.2203 7.35211 1.57955 7.95538 1.50687 8.72674L1.5 8.87324V14.9577C1.5 15.7484 2.09489 16.3982 2.85554 16.4719L3 16.4789H14C14.7797 16.4789 15.4204 15.8756 15.4931 15.1042L15.5 14.9577V8.87324C15.5 8.08256 14.9051 7.43278 14.1445 7.35908Z"
                              fill={
                                errors.password && touched.password
                                  ? 'red'
                                  : '#9B9CAA'
                              }
                            />
                          </svg>

                          <input
                            placeholder="Password"
                            type="password"
                            id="password"
                            name="password"
                            disabled={haveToken}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            className={
                              errors.password && touched.password
                                ? 'inputError'
                                : ''
                            }
                          />
                        </div>
                        <div className="formGroup__errorText">
                          {errors.password}
                        </div>
                      </div>
                      <Button className="getStarted" type="submit"  
                      disabled={haveToken} 
                      style={{pointerEvents: haveToken ? 'none' : 'unset'}} 
                      onClick={handleButtonClick}>
                        <span>Get Started</span>
                      </Button>

                      <span className="terms">
                        {' '}
                        By clicking the button above, you agree to
                        Mendelgen&apos;s <Link href="/privacy" passHref>
                          privacy policy
                        </Link>{' '}
                        and <Link href="/terms" passHref>terms of service</Link>.
                      </span>
                    </form>
                  )}
                </Formik>
              </div>
            </div>

            <div className="content__right">
              <div className="imgBox">
                <Image src={HeroImage} alt="Hero Area" placeholder='blur'/>
              </div>
            </div>
          </div>
        </section>
        <section className="discover">
          <div className="contentBox">
            <h2>Discover the Future of Vector Editing</h2>

            <div className="discover__imgBox">

            <Vimeo
              responsive
              onReady={setPlayer}
              video="737195833"
              autoplay
              loop
              background
            />
            
                        
            </div>
          </div>
        </section>

        <section className="design eightyPercentContainer">
          <div className="design__contentBox">
            <div className="left">
              <Image src={DesignIcon} alt="Design Your Vectors faster" />
              <h2>Intuitive Vector Wizard</h2>
              <span>
              Transform your workflow with our smart, easy-to-use tool for optimized vector design.
              </span>

              <div className="aBox">
                <Link href="/vector-wizard" passHref>Learn more about the Vector Wizard</Link>
              </div>
              <Button
                className="tryWizardBtn"
                onClick={(e) => {
                  if(haveToken) {
                    document.location.href = redirectUrl;
                  } else {
                  setShowSignup(true);
                  }
                }}
              >
                <span>{haveToken ? 'Go To App' : 'Try wizard'}</span>
              </Button>
            </div>
            <div className="right">
              <div className="outerBox">
                <div className="innerBox">
                  {/* <Image src={DesignImg} alt="try Wizard" /> */}

                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                    }}
                  >
                    <Image src={DesignImg} alt="try Wizard" layout="fill" placeholder='blur'/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="fourthSection">
          <div className="contentBox">
            <section className="quick">
              <div className="left">
                <div className="rect1">
                  <div className="rectImgBox">
                    <Image src={rect1} alt="rectangle 1" />
                  </div>
                </div>
                <div className="rect2">
                  <div className="rectImgBox">
                    <Image src={rect2} alt="rectangle 2" />
                  </div>
                </div>
                <div className="rect3">
                  <div className="rectImgBox">
                    <Image src={rect3} alt="rectangle 3" />
                  </div>
                </div>
                <div className="rect4">
                  <div className="rectImgBox">
                    <Image src={rect4} alt="rectangle 4" />
                  </div>
                </div>
                <div className="imgBox">
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                    }}
                  >
                    <Image
                      src={AlignImg}
                      alt="Quickly Align Your Sequences"
                      layout="fill"
                    />
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="iconBox">
                  <Image src={Quick} alt="Quickly align your sequences" />
                </div>
                <h2>Effortless Sequence Alignment</h2>
                <span>
                  Easily align multiple DNA sequences with just a few clicks, saving you time and eliminating potential errors.
                </span>
                <div className="aBox">
                  <Link href="/editing#alignment" passHref>Dive into sequence alignment details</Link>
                </div>
                <Button
                  id="signUp"
                  onClick={(e) => {
                    if(haveToken) {
                      document.location.href = redirectUrl;
                    } else {
                    setShowSignup(true);
                    }
                  }}
                >
                  <span>{haveToken ? 'Go To App' : 'Sign Up'}</span>
                </Button>
              </div>
            </section>

            <section className="codon">
              <div className="codon__left">
                <div className="imgBox">
                  <Image src={Codon} alt="Codon" />
                </div>
                <h2>Instant Codon Optimization</h2>
                <span>
                  Instantly optimize your codons for your target organism, improving your experimental success rate.
                </span>
                <div className="aBox">
                  <Link href="/editing/#Codon-Optimization" passHref>Master codon optimization</Link>
                </div>
                <Button
                  id="tryIt"
                  onClick={(e) => {
                    if(haveToken) {
                      document.location.href = redirectUrl;
                    } else {
                    setShowSignup(true);
                    }
                  }}
                >
                  <span>{haveToken ? 'Go To App' : 'Try it'}</span>
                </Button>
              </div>
              <div className="codon__right">
                <div className="imgBox">
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                    }}
                  >
                    <Image src={CodonImg} alt="Codon" placeholder='blur'/>
                  </div>
                </div>
              </div>
            </section>
            <div className="borderLine eightyPercentContainer"></div>

            <section className="easyOnEyes eightyPercentContainer">
              <div className="easyOnEyes__textContent">
                <h2>Comfortable Dark Mode</h2>
                <span>Enjoy a comfortable, user-friendly experience with our futuristic dark mode interface.</span>
              </div>
              <div className="imgBox">
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                  }}
                >
                  <Image src={DarkMode} alt="Dark Mode" layout="responsive" />
                </div>
              </div>
            </section>
          </div>
        </section>

        <section className="access">
          <div className="imgBg">
            <div className="content">
              <Image src={Access} alt="Access Your Vectors Online" />
              <h2>Vectors, Anytime Anywhere</h2>
              <span>
              Never be without your vectors. Access them from any internet-connected computer, anytime, anywhere.
              </span>
              <Button
                id="join"
                onClick={(e) => {
                  if(haveToken) {
                    document.location.href = redirectUrl;
                  } else {
                  setShowSignup(true);
                  }
                }}
              >
                <span>{haveToken ? 'Go To App' : 'Join'}</span>
              </Button>
            </div>
          </div>
        </section>

        <section className="design design__visualize eightyPercentContainer">
          <div className="design__contentBox">
            <div className="left left__visualize">
              <Image src={VisualizeIcon} alt="Visualize your clones" />
              <h2>In-Silico Cloning Precision</h2>
              <span>
              Experience the power of in-silico cloning. Cut, mix, and ligate with precision, right from your screen.
              </span>
              <div className="aBox">
                  <Link href="/restriction-enzyme-cloning" passHref>Learn how to clone, digitally</Link>
                </div>

              <div className="aBox">
                {/* <a href="/vector-wizard">Learn more</a> */}
              </div>
              <Button
                className="tryWizardBtn tryWizardBtn__visualize"
                onClick={(e) => {
                  if(haveToken) {
                    document.location.href = redirectUrl;
                  } else {
                  setShowSignup(true);
                  }
                }}
              >
                <span>{haveToken ? 'Go To App' : 'Start cloning'}</span>
              </Button>
            </div>
            <div className="right right__visualize">
              <div className="outerBox">
                <div className="innerBox innerBox__visualize">
                  <Image src={cloneTool} alt="DNA cloning tool" placeholder='blur'/>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="feature">
          <div className="feature__top">
            <h2>Advanced Features Unleashed</h2>
            <span>
            Explore a world of advanced molecular biology features at your fingertips
            </span>
          </div>

          <div className="feature__lists">
            <div className="feature__lists--top">
              <div className="feature__list feature__list--1">
                <h3 className="title">Visualize your vectors</h3>
                <ul>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                      <span>Stunning maps</span>
                    </div>
                  </li>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>

                    <div>
                      <span><Link href="/preferences#Zooming-in-Function" passHref>Zoomable views</Link></span>
                    </div>
                  </li>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                      <span>Features, primers, and parts</span>
                    </div>
                  </li>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                      <span>Enzyme sites</span>
                    </div>
                  </li>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                      <span><Link href="/settings#Thermodynamic-Settings" passHref>Melting temperature</Link></span>
                    </div>
                  </li>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                      <span><Link href="/preferences#Dark-Mode" passHref>Dark mode</Link></span>
                    </div>
                  </li>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                      <span>GC %</span>
                    </div>
                  </li>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                      <span>Open reading frames</span>
                    </div>
                  </li>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                      <span>Frame by frame translations</span>
                    </div>
                  </li>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                      <span>Chromatograms</span>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="feature__list feature__list--2">
                <h3 className="title">Save time</h3>
                <ul>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                      <span><Link href="/vector-wizard" passHref>Automatic vector design</Link></span>
                    </div>
                  </li>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                      <span><Link href="/editing#Codon-Optimization" passHref>Codon optimization</Link></span>
                    </div>
                  </li>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                      <span><Link href="/sequence#Insert-Common-Feature" passHref>Insert common features</Link></span>
                    </div>
                  </li>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                      <span><Link href="/sequence#Adding-a-Feature-or-a-Part" passHref>Insert your own features</Link></span>
                    </div>
                  </li>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                      <span><Link href="/settings#Auto-Annotating-Features" passHref>Automatic feature annotation</Link></span>
                    </div>
                  </li>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                      <span>Detect your own features</span>
                    </div>
                  </li>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                      <span>Automatic file conversion</span>
                    </div>
                  </li>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                      <span>Save vectors to your library</span>
                    </div>
                  </li>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                      <span>Undo and redo capability</span>
                    </div>
                  </li>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                      <span>Primer design</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="feature__lists--bottom">
              <div className="feature__list feature__list--3">
                <h3 className="title">Simulate in-silico</h3>
                <ul>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                      <span><Link href="/editing#Simulation-of-Digestion" passHref>Restriction digestion</Link></span>
                    </div>
                  </li>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                      <span><Link href="/restriction-enzyme-cloning" passHref>Restriction cloning</Link></span>
                    </div>
                  </li>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                    <span><Link href="/gibson-assembly" passHref>Gibson assembly</Link></span>
                    </div>
                  </li>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                    <span><Link href="/ta-cloning" passHref>TA cloning</Link></span>
                    </div>
                  </li>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                    <span><Link href="/gateway-cloning" passHref>Gateway cloning</Link></span>
                    </div>
                  </li>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                    <span><Link href="/in-fusion-cloning" passHref>In-Fusion cloning</Link></span>
                    </div>
                  </li>
              
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                    <span><Link href="https://medium.com/@Mendelgen/golden-gate-cloning-simulation-94cf62989ba9" passHref>Golden Gate cloning</Link></span>
                    </div>
                  </li>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                    <span><Link href="/polymerase-chain-reaction" passHref>Polymerase Chain Reaction (PCR)</Link></span>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="feature__list feature__list--4">
                <h3 className="title">Search & align</h3>
                <ul>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                      <span>Search for DNA sequences and features</span>
                    </div>
                  </li>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>
                    <div>
                      <span><Link href="/editing#Sequence-Alignment" passHref>Multiple and pairwise sequence alignment</Link></span>
                    </div>
                  </li>
                  <li>
                    <div style={{ marginRight: 13, marginLeft: -29 }}>
                      <Image src={Tick} alt="Tick" />
                    </div>

                    <div>
                      <span>Sanger sequence alignment</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

      
      <section className="homePlans">
        <div className="plans eightyPercentContainer">
          <div className="mainTitlebox">
            <h2>Plans designed for everyone</h2>
            <span>Already a member?</span>
            <Button
              className="viewPlans"
              onClick={() => {
                localStorage.setItem("showPlanDetails", true);
                setShowLogin(true);
              }}
            >
              <span>View your plan details</span>
            </Button>
          </div>

          <div className="planBoxes">
            <div className="planBox planBox__1">
              <div className="free">Free</div>
              <div className="price">
                <div className="big">$0</div>
                <div>/month</div>
              </div>
              <div className="indi">Individual plan</div>
              <div className="perks">
                <div className="perk">
                  <div className="perk__icon">
                    <Image src={WhiteTick} alt="white tick" />
                  </div>
                  <div className="perk__text">Vector viewer</div>
                </div>
                <div className="perk">
                  <div className="perk__icon">
                    <Image src={WhiteTick} alt="white tick" />
                  </div>
                  <div className="perk__text">Basic editing</div>
                </div>
                <div className="perk">
                  <div className="perk__icon">
                    <Image src={WhiteTick} alt="white tick" />
                  </div>
                  <div className="perk__text">Cloud storage</div>
                </div>
              </div>
              <Button className="getStarted"  onClick={() => {
                localStorage.setItem("autoSelectFreePlan", true);
                setShowSignup(true);
              }}>
                <span>Get Started</span>
              </Button>
            </div>

            <div className="planBox planBox__2">
              <Tab.Container id="left-tabs-example" defaultActiveKey="second">
                <Row>
                  <Nav variant="pills" className="flex-row plan-tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Monthly</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Annual</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Row>
                <Row className="mt-4">
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div className="free">Pro</div>
                      <div className="price">
                        <div className="big">$6</div>
                        <div>/month</div>
                      </div>
                      <div className="indi">Individual plan</div>
                      <div className="perks">
                        <div className="perk">
                          <div className="perk__icon">
                            <Image src={BlueTick} alt="white tick" />
                          </div>
                          <div className="perk__text">Vector viewer</div>
                        </div>
                        <div className="perk">
                          <div className="perk__icon">
                            <Image src={BlueTick} alt="white tick" />
                          </div>
                          <div className="perk__text">Advanced editing</div>
                        </div>
                        <div className="perk">
                          <div className="perk__icon">
                            <Image src={BlueTick} alt="white tick" />
                          </div>
                          <div className="perk__text">Cloud storage</div>
                        </div>
                        <div className="perk">
                          <div className="perk__icon">
                            <Image src={BlueTick} alt="white tick" />
                          </div>
                          <div className="perk__text">Cloning simulations</div>
                        </div>
                        <div className="perk">
                          <div className="perk__icon">
                            <Image src={BlueTick} alt="white tick" />
                          </div>
                          <div className="perk__text">Vector wizard</div>
                        </div>
                        <div className="perk">
                          <div className="perk__icon">
                            <Image src={BlueTick} alt="white tick" />
                          </div>
                          <div className="perk__text">Sequence alignments</div>
                        </div>
                        <div className="perk">
                          <div className="perk__icon">
                            <Image src={BlueTick} alt="white tick" />
                          </div>
                          <div className="perk__text">Codon optimization</div>
                        </div>
                        <div className="perk">
                          <div className="perk__icon">
                            <Image src={BlueTick} alt="white tick" />
                          </div>
                          <div className="perk__text">Feature detection</div>
                        </div>
                        <div className="perk">
                          <div className="perk__icon">
                            <Image src={BlueTick} alt="white tick" />
                          </div>
                          <div className="perk__text">Automatic annotation</div>
                        </div>
                      </div>
                      <Button
                        className="tryPro"
                        onClick={() => {
                          localStorage.setItem("tryProPlan", true);
                          setShowSignup(true);
                        }}
                      >
                        <span>Try Pro</span>
                      </Button>
                    </Tab.Pane>

                    <Tab.Pane eventKey="second">
                      <div className="free">Pro</div>
                      <div className="price">
                        <div className="big">$49</div>
                        <div>/year</div>
                      </div>
                      <div className="indi">Individual plan</div>
                      <div className="perks">
                        <div className="perk">
                          <div className="perk__icon">
                            <Image src={BlueTick} alt="white tick" />
                          </div>
                          <div className="perk__text">Vector viewer</div>
                        </div>
                        <div className="perk">
                          <div className="perk__icon">
                            <Image src={BlueTick} alt="white tick" />
                          </div>
                          <div className="perk__text">Advanced editing</div>
                        </div>
                        <div className="perk">
                          <div className="perk__icon">
                            <Image src={BlueTick} alt="white tick" />
                          </div>
                          <div className="perk__text">Cloud storage</div>
                        </div>
                        <div className="perk">
                          <div className="perk__icon">
                            <Image src={BlueTick} alt="white tick" />
                          </div>
                          <div className="perk__text">Cloning simulations</div>
                        </div>
                        <div className="perk">
                          <div className="perk__icon">
                            <Image src={BlueTick} alt="white tick" />
                          </div>
                          <div className="perk__text">Vector wizard</div>
                        </div>
                        <div className="perk">
                          <div className="perk__icon">
                            <Image src={BlueTick} alt="white tick" />
                          </div>
                          <div className="perk__text">Sequence alignments</div>
                        </div>
                        <div className="perk">
                          <div className="perk__icon">
                            <Image src={BlueTick} alt="white tick" />
                          </div>
                          <div className="perk__text">Codon optimization</div>
                        </div>
                        <div className="perk">
                          <div className="perk__icon">
                            <Image src={BlueTick} alt="white tick" />
                          </div>
                          <div className="perk__text">Feature detection</div>
                        </div>
                        <div className="perk">
                          <div className="perk__icon">
                            <Image src={BlueTick} alt="white tick" />
                          </div>
                          <div className="perk__text">Automatic annotation</div>
                        </div>
                      </div>
                      <Button className="tryPro"
                      onClick={() => {
                        localStorage.setItem("tryProPlan", true);
                        setShowSignup(true);
                      }}>
                        <span>Try Pro</span>
                      </Button>
                    </Tab.Pane>
                  </Tab.Content>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </section>
      
        <section className="homeBeta eightyPercentContainer">
          <div className="bgBox">
            <div className="betaContent">
              <Image src={Beta} alt="Get Free Access in Beta" />
              <div className="betaContent__text">
              Start free. Upgrade to unlock advanced features and amplify your research.
              </div>
              {/* <Button
              className="joinBeta"
              onClick={(e) => {
                setShowSignup(true);
              }}
            >
              <span>Access the editor</span>
            </Button> */}

              <div
                className="joinBeta"
                onClick={(e) => {
                  if(haveToken) {
                    document.location.href = redirectUrl;
                  } else {
                  setShowSignup(true);
                  }
                }}
              >
                <svg
                  width="175"
                  height="50"
                  viewBox="0 0 175 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 25C0 11.1929 11.1929 0 25 0H150C163.807 0 175 11.1929 175 25V25C175 38.8071 163.807 50 150 50H25C11.1929 50 0 38.8071 0 25V25Z"
                    fill="url(#paint0_linear_0_19658)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_0_19658"
                      x1="269.347"
                      y1="25.8568"
                      x2="246.012"
                      y2="-65.3319"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#8713FF" stopOpacity="0.01" />
                      <stop offset="1" stopColor="white" />
                    </linearGradient>
                  </defs>
                </svg>
                <div className="joinBeta__text">{haveToken ? 'Go To App' : 'Start Free'}</div>
              </div>
            </div>
          </div>
        </section>
        <Footer isSlug={false}/>
      </div>
    </div>
    </SSRProvider>);
}
